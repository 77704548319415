<template>
      <div id="productSpecsElementForm" class="form">
        <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
          <v-select
            v-model="productSpecsElement.productSpecs"
            :items="productSpecsElement.relations.productSpecs"
            label="Product Specs"
            :multiple="false"
            item-text="product"
            item-value="id"
            chips
            deletable-chips
          ></v-select><v-select
            v-model="productSpecsElement.element"
            :items="productSpecsElement.relations.element"
            label="Element"
            :multiple="false"
            item-text="element_name"
            item-value="id"
            chips
            deletable-chips
          ></v-select><v-text-field
              v-model="productSpecsElement.minPercent"
              label="Min Percent"
              :required="false"
            />
            <v-text-field
              v-model="productSpecsElement.maxPercent"
              label="Max Percent"
              :required="false"
            />
            
        <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack">
          Back
        </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
        </v-form>
      </div>
    </template>
    
<script>
  import { getProductSpecsElement } from "@/services/productSpecsElement";
  
              import { getAllProductSpecs } from "@/services/productSpecs";
            
              import { getAllLabElements } from "@/services/labElement";
            
  
  import { mapActions } from "vuex";

  

  export default {
    props: ["id"],
    data () {
      return {
        loaded: false,
        valid: false,
        productSpecsElement: {
          relations: {
            productSpecs: [],
element: [],

          },
          productSpecs: {},
element: {},
minPercent: '',
maxPercent: '',

        }
      }
    },
    created() {
      this.setInstance();
    },
    methods: {
      ...mapActions("productSpecsElement", ["createProductSpecsElement", "updateProductSpecsElement"]),
      ...mapActions("messages", [
        "addMessage",
        "addErrorMessage",
        "addSuccessMessage"
      ]),
      
      async handleSubmit() {
        if (this.id) {
          let productSpecsElement = this.productSpecsElement;
          delete productSpecsElement.relations;

          this.updateProductSpecsElement(productSpecsElement)
          .then( (response) => {
            console.log(response);
            this.addSuccessMessage("ProductSpecsElement was updated successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error updating ProductSpecsElement, please try again later");
          });
        } else {
          let productSpecsElement = this.productSpecsElement;
          delete productSpecsElement.relations;

          this.createProductSpecsElement(productSpecsElement)
          .then( response => {
            console.log(response);
            this.addSuccessMessage("ProductSpecsElement was created successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error creating ProductSpecsElement, please try again later");
          })
        }
      },
      goBack() {
        this.$router.go(-1);
      },
      setInstance() {
        if (this.id) {
          this.productSpecsElement.id = this.id;
          getProductSpecsElement(this.id)
            .then(response => {
              let instance = response.data;
              for (let property in instance) {
                if (Object.prototype.hasOwnProperty.call(instance, property) && Object.prototype.hasOwnProperty.call(this.productSpecsElement, property)) {
                  this.productSpecsElement[property] = instance[property];
                }
              }
              this.loaded = true;
            })
        } else {
          this.loaded = true;
        }
        this.setDependencies();
      },
      setDependencies() {
        
            const params_productSpecs = {
              pageIndex: 1,
              pageSize: 100
            };
            getAllProductSpecs(params_productSpecs)
              .then(response => {
                this.productSpecsElement.relations.productSpecs = response.data.content;
              });
            
            const params_element = {
              pageIndex: 1,
              pageSize: 100
            };
            getAllLabElements(params_element)
              .then(response => {
                this.productSpecsElement.relations.element = response.data.content;
              });
            
      }
    },
    components: {
      
    }
  }
</script>
